import { datadogLogs } from "@datadog/browser-logs";
import { ErrorLevel } from "~/models/enum/ErrorLevel";

/**
 * Logger reporting errors and messages to our datadog logging system
 */
export default class AnybillLogger {
  private static _instance: AnybillLogger;
  private get isProduction() {
    return process.env.NODE_ENV === "production";
  }

  private get currentEnv() {
    return useRuntimeConfig().public.apiEnvironment ?? "-";
  }

  /**
   * Install method that will be called from nuxt plugin injector
   */
  public static install(): void {
    AnybillLogger.instance.init();
  }

  private async init(): Promise<void> {
    datadogLogs.init({
      clientToken: "pub3a4f289290e469674690cf092e6fb651",
      site: "datadoghq.eu",
      sessionSampleRate: 100,
      service: "partner-portal-v3",
      env: this.currentEnv,
      beforeSend: (log, _) => {
        if (
          log.message.includes("XHR error GET")
          // This is thrown for users with an active adblocker.
          || log.message.includes("XHR error POST https://px.ads.linkedin.com/wa/")
          || log.message.includes("Fetch error GET")
          || log.message.includes("ResizeObserver loop completed with undelivered notifications")
        )
          return false;
        return true;
      },
    });
  }

  public static setUser(email: string): void {
    datadogLogs.setUser({
      email,
    });
  }

  public static getUser(): import("@datadog/browser-core").Context {
    return datadogLogs.getUser();
  }

  public static removeUser(): void {
    datadogLogs.setUser({});
  }

  /**
   * Returns an instance of the class.
   */
  static get instance(): AnybillLogger {
    if (AnybillLogger._instance === undefined)
      AnybillLogger._instance = new AnybillLogger();

    return AnybillLogger._instance;
  }

  /**
   * Call a debug event.
   * Will not give any information in PROD level.
   */
  async debug(
    msg: string,
  ): Promise<void> {
    await this._log({
      message: msg,
      level: ErrorLevel.Debug,
    });
  }

  /**
   * Call an info event.
   */
  async info(
    msg: string,
  ): Promise<void> {
    await this._log({
      message: msg,
      level: ErrorLevel.Info,
    });
  }

  /**
   * Call a warn level event.
   */
  async warn(
    msg: string,
    errObj?: Error,
    className?: string,
  ): Promise<void> {
    await this._log({
      message: msg,
      level: ErrorLevel.Warn,
      errorObject: errObj,
      className,
    });
  }

  /**
   * Call an Error level event.
   */
  async error(
    msg: string,
    errObj?: Error,
    className?: string,
  ): Promise<void> {
    await this._log({
      message: msg,
      level: ErrorLevel.Error,
      errorObject: errObj,
      className,
    });
  }

  /**
   *
   * Logs the error with the specified parameters
   *
   * @async
   * @param {{
   *     message: string;
   *     level: ErrorLevel;
   *   }} _params Multiple parameters for the data
   * @returns {Promise<void>} No return, exception when error is happening but
   *            it won't break
   */
  private async _log(_params: {
    message: string;
    level: ErrorLevel;
    errorObject?: Error;
    className?: string;
  }): Promise<void> {
    if (!this.isProduction) {
      this._logLocally(_params);
    }
    // Send logging event to datadog
    else {
      const errorMessage = `[${_params.className ?? "unknown"}] ${_params.message}`;
      switch (_params.level) {
        case ErrorLevel.Debug:
          if (!this.isProduction)
            datadogLogs.logger.debug(errorMessage, _params.errorObject, _params.errorObject);
          break;
        case ErrorLevel.Info:
          datadogLogs.logger.info(errorMessage, _params.errorObject, _params.errorObject);
          break;
        case ErrorLevel.Warn:
          datadogLogs.logger.warn(errorMessage, _params.errorObject, _params.errorObject);
          break;
        case ErrorLevel.Error:
          datadogLogs.logger.error(errorMessage, _params.errorObject, _params.errorObject);
          break;
      }
    }
  }

  private _logLocally(_params: {
    message: string;
    level: ErrorLevel;
    errorObject?: Error | undefined;
  }): void {
    if (_params.level === ErrorLevel.Error || _params.level === ErrorLevel.Warn)
      // eslint-disable-next-line no-console
      console.trace(_params.errorObject, [_params.message]);
    else
      // eslint-disable-next-line no-console
      console.info(_params.message);
  }
}
